
import {React, useState} from 'react'
import BusinessCard from '../BusinessCard/BusinessCard';

import './PanelSupport.less';
import photo1 from "../../assets/images/j1.jpg";
import photo2 from "../../assets/images/j2.jpg";
import photo3 from "../../assets/images/j3.jpg";
import photo4 from "../../assets/images/j4.jpg";
import photo5 from "../../assets/images/j5.jpg";
import photo6 from "../../assets/images/j6.jpg";
import photo7 from "../../assets/images/j7.jpg";
import photo8 from "../../assets/images/j8.jpg";
import photo9 from "../../assets/images/j9.jpg";
import photo10 from "../../assets/images/j10.jpg";
import F1 from "../../assets/images/StandardSizeChart.png";
import F2 from "../../assets/images/LargeSizeChart.png";
import F3 from "../../assets/images/StandardSupport_underside_clamp.jpg";
import F4 from "../../assets/images/closeup.jpg";
import ImageCarousel from "../ImageCarousel/ImageCarousel.tsx";
import $ from 'jquery';
window.$ = $;
//const IMAGES = [photo1, photo2, photo3, photo4, photo7, F1, F3];

export default function PanelSupport({callback}) {

  // Turn off the header
  window.bShowHeader = "none";

  const [imageIndex, setimageIndex] = useState(0)
  const slides = [

    {url:photo4, title:'Use Anywhere'},
    {url:photo10, title:'More Than One For Larger Panels'},
   // {url:photo1, title:'Standard Size Fully Extended'},
    {url:photo2, title:'Standard Size Fully Compressed'},
    {url:photo8, title:'Conveniently Folds For Storage'},
    {url:photo9, title:'Features'},
  
    {url:photo7, title:'Telescoping with Twist-Lock'}, 
    {url:photo3, title:'360º Ball Clamp'},

    //{url:photo6, title:'1-1/8\" Rubberized Foot'},
  
    //{url:photo5, title:'photo 5'},
    {url:F1, title:'Standard Size Fits Most Applications'},
    {url:F2, title:'For high angles and larger panels'},
    {url:F3, title:'360º Swivel Clamps To Panel Frame'}
    
  ];

  const introT = ["Quick Setup"];
  const introP = ["There is a quick and easy solution for setting solar panel inclination. Clamp this support to a solar panel frame to be able to quickly adjust the panel's angle."];
  const introP1 = ["This compact, telescoping support is made for on-the-go activities like day trips, picnics, camping and other outdoor activities."];
  const introP2 = [" While traditional, fixed angled supports are often too bulky to pack and too much work to assemble and disassemble, these telescoping supports can quickly allow you to get the perfect panel inclination on all types of terrain."];
  const introP3 = ["Designed for solar panel enthusiasts - whether it's camping, a day at the flea market, or maybe picnicking out at the beach, this telescoping swivel-clamp is the compact solution for easily adjusting panel inclination."];




  $("#fading-words-display").text("");

  $(document).prop("title", "Solar Panel Support");
      
  return (

    <div className='PanelSupport' style={{position:'relative'}}>


        <div className="container" id="PanelSupport">
          
        <div style={{marginTop:'.3em',marginBottom:'4em', textAlign:'center'}}>
          <h2>
            Twist-Lock Telescoping Panel Support with 360&#x00B0; Swivel Clamp
          </h2>
        </div>



 
        <div className="row justify-content-center" style={{margin:'0 0 3em 0',textAlign:'center', position:'relative'}}>
         
          <div className="col-lg-5 col-md-12" >  
            <div className="">
              <ImageCarousel imageUrls={slides} style={{}}/>
            </div>
          </div>
          <div className="col-lg-5 col-md-12" >  
            <div className="article">

            {/* d-none d-md-block */}

              {/* space */}
              <div className='d-block d-sm-block d-md-block d-lg-none' style={{height:'3em'}}/>

              {/* <div style={{position:'relative',float:'right', top:0}}>
                <ImageCarousel imageUrls={slides} />
              </div>  */}
              <h4 style={{margin:'1.0em 0', fontWeight:'600'}}>{introT}</h4>

              <p>{introP}</p>
              <p>{introP1}</p>
              <p>{introP2}</p>
              <p>{introP3}</p>

            </div>
          </div>
        </div>

        <div className="row justify-content-center" style={{margin:'3em 0 .7em 0',textAlign:'center'}}>
          <div className="col-lg-5 col-md-5" >
            <div style={{height:'100%', alignContent:'end'}} >
              
              {/* <h4><strong>The Simple Solution</strong></h4> */}
            </div>
          </div>
        </div>

        <div className="row justify-content-center" style={{textAlign:'center'}}>
          <div className="col-lg-12 col-md-12" >
             <img src={F4} />
            {/* <h5 style={{textAlign:'center', marginTop:'.7em'}}>Ideal For Uneven Surfaces</h5> */}
          </div>
        </div>


        <div className="row justify-content-center" style={{textAlign:'center', marginBottom:'2em'}}>
        <div className="col-lg-5 col-md-5" style={{margin:'3em 0 0em 0'}}>
            <div className="whimg" style={{ height:'100%', alignContent:'end'}} >
              <img src={F1} style={{height:'90%'}}></img>
              {/* <h4><strong>Standard Size</strong></h4> */}
            </div>
          </div>
          <div className="col-lg-5 col-md-5" style={{margin:'3em 0 0em 0'}}>
            <div className="whimg" >
              <img src={F2} ></img>
              {/* <h4><strong>Large</strong></h4> */}
            </div>
          </div>
        </div>


        <div className="row justify-content-center" style={{textAlign:'center'}}>
          <div className="col-lg-12 col-md-12" >
             <img src={photo5} />
            <h5 style={{textAlign:'center', marginTop:'.7em'}}>Ideal For Uneven Surfaces</h5>
          </div>
        </div>



        <div className="row justify-content-center" style={{textAlign:'center', marginBottom:'2em'}}>
        <div className="col-lg-5 col-md-5" style={{margin:'5em 0 0em 0'}}>
            <div className="whimg" style={{ height:'100%', alignContent:'end', fontWeight:'700', color:'#348586'}} >
               <a href='/downloads/TPS_Instruction_Sheet.pdf' target='_blank'>Instructions (pdf file)</a>
 
            </div>
          </div>
          <div className="col-lg-5 col-md-5" style={{margin:'3em 0 0em 0'}}>
            <div className="whimg" style={{ height:'100%', alignContent:'end', fontWeight:'700', color:'#348586', textDecoration:'none'}} >
               <a href='https://www.amazon.com/dp/B0D4R7YSQ1?ref=myi_title_dp' target='_blank'>Available on Amazon</a>
            </div>
          </div>

        </div>
            

        {/* <div className="business-card">
          <BusinessCard></BusinessCard>
        </div> */}


        {/* <div className="row " style={{padding:'0 0 0 0', margin:'0 0 0 0'}}>
          <div className="col-md-12" >
            <table className="right-column d-none d-md-block" style={{textAlign: 'right'}}>
              <tbody>
                <tr>
                <td>info@OtterbineSolutions.com</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div> */}

       {/* <div style={{height:'6em', padding:'4em 1em 0 0'}}>
        <div style={{marginRight:'-1em', textAlign: 'right', fontFamily:"Calibri", fontWeight:'400', fontSize:'22px' }}>info@OtterbineSolutions.com</div>
      </div>  
   */}






      </div>
    </div>
    
    
    
    
    
    
    
    




      );
  }







  
  //export default PanelSupport