import logo from './logo.svg';
import './App.less';
import ReactIntro from './Components/ReactIntro'
//import { Header, Footer, NotFound }  from './Components';
import Header  from './Components/Header/Header'
import HashingExample from './Components/HashingExample/HashingExample'
import Footer  from './Components/Footer/Footer'
import Home from './Components/Home'
import PanelSupport from './Components/PanelSupport/PanelSupport'
import NotFound from './Components/NotFound'
import Resume from './Components/MOResume/MOResume'
import OBDIIAppPrivacyPolicy from './Components/PrivacyPolicies/OBDIIAppPrivacyPolicy'
import OBD2AppPrivacyPolicy from './Components/PrivacyPolicies/OBD2AppPrivacyPolicy'
import NorthFinderAppPrivacyPolicy from './Components/PrivacyPolicies/NorthFinderAppPrivacyPolicy'
import MoneyTimesAppPrivacyPolicy from './Components/PrivacyPolicies/MoneyTimesAppPrivacyPolicy'
import BucketBallAppPrivacyPolicy from './Components/PrivacyPolicies/BucketBallAppPrivacyPolicy'
import MoonDefenderAppPrivacyPolicy from './Components/PrivacyPolicies/MoonDefenderAppPrivacyPolicy'

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom"

import React, { useLayoutEffect, useState, useEffect } from 'react';

import $ from 'jquery';
import { isVisible } from '@testing-library/user-event/dist/utils';
window.$ = $;
window.bShowHeader = 'relative';

const SetupScrollListener = (props) => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      //let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      let currentPosition = window.scrollY; // or use document.documentElement.scrollTop;
      if (currentPosition > scrollTop) {
        // downscroll code
        $("#sidebar-wrapper").removeClass("active");
        setScrolling(false);
      } else {
        // upscroll code
        $("#sidebar-wrapper").removeClass("active");
        setScrolling(true);
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);

      $("#sidebar-wrapper").removeClass("active");
       
      let hdr = $("#header");
      let ft = $('footer');
  
      let body = $('#bodySection');  
       
      body.css("padding-top", hdr.height());// = 100;//hdr.height();
      var fHt = ft.height() 

      body.css("padding-bottom", ft.height()+ 0);// = 100;//hdr.height();

    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
// const Download = () => {
//   return (
//       <div>
//           <h1>Download Area</h1>
//           <a href="//assets//TPS_Instruction_Sheet.pdf">Download</a>
//       </div>
//   );
// };

const App = () => {
  const [width, height] = useWindowSize();
  var j = SetupScrollListener();
    return (

    <div>

      <div style={{display:window.bShowHeader}}>
        <Header/>
        </div>
      <BrowserRouter>
      <div id="bodySection"> 
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route index element={<Home />} />
          {/* <Route path="Download" component={Download} /> */}
          {/* <Route path="Download" element={<a href="/assets/TPS_Instruction_Sheet.pdf" style={{marginTop:'100px'}}>Download</a>} /> */}
          <Route path="home" element={<Home />} />
          <Route path="PanelSupport" element={<PanelSupport/>} />
          <Route path="OSNFPrivacyPolicy" element={<NorthFinderAppPrivacyPolicy />} />
          <Route path="OBD2AppPrivacyPolicy" element={<OBD2AppPrivacyPolicy />} />
          <Route path="OBDIIAppPrivacyPolicy" element={<OBDIIAppPrivacyPolicy />} />
          <Route path="OSICAppPrivacyPolicy" element={<MoneyTimesAppPrivacyPolicy />} />
          <Route path="BucketBallAppPrivacyPolicy" element={<BucketBallAppPrivacyPolicy />} />
          <Route path="MoonDefenderAppPrivacyPolicy" element={<MoonDefenderAppPrivacyPolicy />} />
          <Route path="MikeOtterbineResume" element={<Resume />} />
          <Route path="HashingExample" element={<HashingExample />} />
          <Route path="contact">
            <Route index element={<Home />} />
            <Route path=":id" element={<ReactIntro />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>

      {/* <div style={{height:'10em', background:'#DDD',padding:'1em 1em 0 0'}}>
        <div style={{textAlign: 'right', fontFamily:"Calibri", fontWeight:'400', fontSize:'22px' }}>info@OtterbineSolutions.com</div>
      </div> */}

     </div>
      </BrowserRouter>

     

      <div style={{display:window.bShowHeader}}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
